import _ from "lodash";
import { ReactNode } from "react";
import { Stack } from "@samacare/design";
import {
  useFormContext,
  TextField,
  TextFieldProps,
  AutocompleteFieldProps,
  ProviderAutocompleteDataWrapper,
} from "@samacare/form";
import { PrescriberInfoFragment } from "@@generated/graphql";
import { HelpTag } from "@samacare/layout/HelpTag";
import { useApolloClient } from "@apollo/client";

type PrescriberAutocompleteProps = Omit<
  AutocompleteFieldProps<PrescriberInfoFragment, false, false, false>,
  | "name"
  | "options"
  | "onInputChange"
  | "inputValue"
  | "value"
  | "open"
  | "onOpen"
  | "onClose"
  | "label"
>;

export type PrescriberBlockProps = {
  required?: boolean;
  disabled?: boolean;
  showPtan?: boolean;
  allowNew?: boolean;
  FirstNameInputProps?: TextFieldProps;
  LastNameInputProps?: TextFieldProps;
  SpecialtyDescriptionInputProps?: TextFieldProps;
  NPIInputProps?: TextFieldProps;
  TINInputProps?: TextFieldProps;
  IdInputProps?: TextFieldProps;
  PTANInputProps?: Partial<TextFieldProps>;
  PrescriberAutocompleteInputProps?: PrescriberAutocompleteProps;
  chooseDisabled?: boolean;
  children?: ReactNode;
  name?: string;
};

const defaultProps: PrescriberBlockProps = {
  required: false,
  disabled: false,
  showPtan: false,
  allowNew: true, // Note: this is a temporary prop to prevent users from entering a new office for BVs (the backend doesnt support it yet)
  IdInputProps: { name: "PrescriberId" },
  FirstNameInputProps: { name: "prescriber.firstName", label: "First name" },
  LastNameInputProps: { name: "prescriber.lastName", label: "Last name" },
  SpecialtyDescriptionInputProps: {
    name: "prescriber.specialtyDescription",
    label: "SpecialtyDescription",
  },
  NPIInputProps: { name: "prescriber.NPI", label: "NPI #" },
  TINInputProps: { name: "prescriber.TIN", label: "Tax ID" },
  PTANInputProps: { name: "prescriber.ptan", label: "PTAN #" },
  PrescriberAutocompleteInputProps: {},
  chooseDisabled: false,
};

const PrescriberBlock: React.FC<PrescriberBlockProps> = (rawProps) => {
  const {
    required,
    disabled,
    IdInputProps,
    FirstNameInputProps,
    LastNameInputProps,
    TINInputProps,
    NPIInputProps,
    SpecialtyDescriptionInputProps,
    PTANInputProps,
    PrescriberAutocompleteInputProps,
    chooseDisabled,
    allowNew,
    showPtan,
    children,
    name,
  } = _.merge(
    _.cloneDeep(defaultProps),
    rawProps
  ) as Required<PrescriberBlockProps>;
  const { reset, getValues, watch, setValue } = useFormContext();

  const apolloClient = useApolloClient();

  const cannotChoose = disabled || chooseDisabled;
  const fieldsDisabled = !allowNew || disabled;

  return (
    <Stack gap={2} maxWidth={600}>
      <Stack direction="row" alignItems="center">
        <ProviderAutocompleteDataWrapper
          apolloClient={apolloClient}
          name={name ?? "PrescriberBlock"}
          placeholder={cannotChoose ? "" : "Choose..."}
          filterSelectedOptions
          onChange={(_event, change, reason) => {
            reason === "selectOption"
              ? reset(
                  {
                    ...getValues(),
                    PrescriberId: change?.id ?? undefined,
                  },
                  { keepDirty: true }
                )
              : reset(
                  {
                    ...getValues(),
                    PrescriberId: null,
                  },
                  { keepDirty: true }
                );

            setValue(
              "prescriber",
              reason === "selectOption"
                ? { ...getValues().prescriber, ...change }
                : null,
              { shouldDirty: true }
            );
          }}
          disabled={cannotChoose}
          {...PrescriberAutocompleteInputProps}
        />

        {!allowNew && (
          <HelpTag text="To add a prescriber, visit practice settings" />
        )}
      </Stack>
      <TextField
        sx={{ display: "none" }}
        type="hidden"
        variant="filled"
        value={watch("PrescriberId")}
        {...IdInputProps}
      />
      <Stack gap={1} direction="row" maxWidth={600}>
        <TextField
          required={required}
          disabled={fieldsDisabled}
          fullWidth
          {...FirstNameInputProps}
        />
        <TextField
          required={required}
          disabled={fieldsDisabled}
          fullWidth
          {...LastNameInputProps}
        />
      </Stack>
      <Stack gap={1} direction="row" maxWidth={600}>
        <TextField
          required={required}
          disabled={fieldsDisabled}
          fullWidth
          {...SpecialtyDescriptionInputProps}
        />
        <TextField
          required={required}
          disabled={fieldsDisabled}
          fullWidth
          rules={{
            validate: (value) => value.length === 10,
          }}
          {...NPIInputProps}
        />
      </Stack>
      <TextField
        required={required}
        disabled={fieldsDisabled}
        fullWidth
        {...TINInputProps}
      />

      {showPtan && (
        <TextField
          name="prescriber.ptan"
          label="PTAN #"
          required={required}
          disabled={fieldsDisabled}
          fullWidth
          variant="filled"
          {...PTANInputProps}
        />
      )}
      {children}
    </Stack>
  );
};

export default PrescriberBlock;
