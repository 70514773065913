import React from "react";
import { Checkbox, Paper, TextField, Typography } from "@samacare/design";
import styled from "styled-components";

const Block = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 16px;
  border-radius: 4px;
  border-top: 4px solid rgba(99, 99, 242, 0.5);
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  margin-bottom: 8px;
`;

const CheckboxSection = styled.div`
  display: flex;
  align-items: center;
`;

const InputText = styled(TextField)`
  height: 90px;
  margin: 16px 0;
`;

const CheckboxWarning = styled(Typography)`
  color: #d32f2f;
  font-size: 12px;
`;

const UnderlinedButton = styled.div`
  color: #6363f2;
  text-decoration: underline;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
`;

const CoverLetter: (props: CoverLetterProps) => JSX.Element = ({
  onCheckboxChange,
  checkboxValue,
  coverLetterNote,
  setCoverLetterNote,
  disabled,
  openEditModal,
}: CoverLetterProps) => {
  return (
    <Block>
      <Header>
        <Typography variant="h6" gutterBottom>
          Cover Letter
        </Typography>
        <Typography variant="body2">
          Cover letters improve chances of Prior Authorization approval.
          SamaCare automatically provides a pre-filled cover letter for you.
        </Typography>
      </Header>
      <CheckboxSection>
        <Checkbox
          onChange={() => {
            if (onCheckboxChange) {
              onCheckboxChange(!checkboxValue);
            }
          }}
          checked={checkboxValue}
          value={checkboxValue}
          disabled={disabled}
        />
        <Typography>Include cover letter</Typography>
      </CheckboxSection>
      {!checkboxValue && (
        <CheckboxWarning>
          Including a cover letter improves chances of approval.
        </CheckboxWarning>
      )}
      {checkboxValue && (
        <>
          {coverLetterNote !== null && (
            <InputText
              multiline
              name="note"
              label="Notes for cover letter (optional)"
              placeholder="Add a note to be included in the pre-filled cover letter"
              value={coverLetterNote}
              onChange={(e) => {
                if (setCoverLetterNote) {
                  setCoverLetterNote(e.target.value);
                }
              }}
              rows={3}
              disabled={disabled}
            />
          )}
          {openEditModal && (
            <div>
              <UnderlinedButton onClick={openEditModal}>
                Edit Cover Letter
              </UnderlinedButton>
            </div>
          )}
        </>
      )}
    </Block>
  );
};

interface CoverLetterProps {
  onCheckboxChange: (b: boolean) => void;
  checkboxValue: boolean;
  disabled: boolean;
  coverLetterNote: string;
  setCoverLetterNote: (s: string) => void;
  openEditModal: () => void;
}

export default CoverLetter;
