import moment from "moment";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@samacare/design";
import {
  FormProvider,
  PatientBlock,
  useForm,
  EdiInsuranceBlock,
  ServiceCodeDataWrapper,
  PaProviderBlock,
} from "@samacare/form";
import { EnrollmentSection } from "../../Enrollment/EnrollmentSection";
import { PortalAuth } from "../../../types/PortalAuth";
import { useCurrentAccount } from "../../../graphql/Account";
import { useConfig } from "@@hooks/config";
import { doCreate } from "../../../util/authUtils";
import { useAlert } from "react-alert";
import { LeftRightCenterAll } from "@samacare/component";
import {
  Authorization,
  AuthorizationStatusEnum,
  HcpcsCode,
  PaOriginType,
  SubscriberRelationshipCode,
} from "@samacare/graphql";
import { PortalContext } from "app/contexts/portalContext";
import { useFeatureFlag } from "@@hooks/useFeatureFlag";
import { sendMsgToBgScript } from "app/util/bgScriptMsgUtils";
import {
  useCreatePatient,
  useWriteAuthorizationResultsToPatient,
} from "app/graphql/Patient";
import {
  useUpdateAuthorization,
  useCreateAuthorization,
  useSetPrescriberOnAuth,
} from "app/graphql/Authorization";
import { useContext, useCallback, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { ExtensionAlertBanner } from "@@components/ExtensionAlertBanner";
import { HeaderTitle } from "../../../constants/HeaderTitle";
import { WebExtensionPubSub } from "../../../services/WebExtensionPubSub";

export const PortalAuthorizationCreate = () => {
  const config = useConfig();
  const [currentAccount] = useCurrentAccount();
  const apolloClient = useApolloClient();
  const methods = useForm<{
    PatientId?: string;
    patient: {
      firstName: string;
      lastName: string;
      dob: string;
      primaryInsurance: {
        memberId: string;
        InsuranceCompanyId: string;
        relationshipToSubscriber: SubscriberRelationshipCode;
      };
    };
    InstitutionId: string;
    PrescriberId: string;
    insuranceCompanyName: string;

    services: HcpcsCode[];
    requestingProvider: {
      npi: string;
      taxId: string;
      firstName: string;
      lastName: string;
      PrescriberId: string;
      specialty: string;
    };
  }>({
    defaultValues: {},
    mode: "onChange",
  });
  const alert = useAlert();
  const [createAuthorization] = useCreateAuthorization();
  const [createPatient] = useCreatePatient();
  const [writeAuthResToPatient] = useWriteAuthorizationResultsToPatient();
  const enableBlendedPortalWorkflow = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableBlendedPortalWorkflow
  );

  const { isValid } = methods.formState;

  const { portalData } = useContext(PortalContext);
  const history = useHistory();

  const [updateAuthorization] = useUpdateAuthorization();
  const [setPrescriberOnAuth] = useSetPrescriberOnAuth();

  const handleSubmit = useCallback(
    _.debounce(
      methods.handleSubmit(async (formData) => {
        let draftedPA: Authorization | undefined | null;
        if (!currentAccount?.InstitutionId)
          return alert.error("InstitutionId is missing");

        const portalAuth: PortalAuth = {
          InstitutionId: currentAccount?.InstitutionId,
          HCPCSCodes: formData.services,
        };

        if (!portalData?.portal?.isLegacy) {
          let finalPatientId = formData.PatientId;
          if (!finalPatientId) {
            const { data } = await createPatient({
              variables: {
                object: {
                  firstName: formData.patient.firstName,
                  lastName: formData.patient.lastName,
                  dob: moment(formData.patient.dob).format("YYYY-MM-DD"),
                  InstitutionId: currentAccount?.InstitutionId,
                },
              },
            });
            finalPatientId = data?.createPatient?.id;
          }

          const resDraftedPA = await doCreate({
            type: config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.key,
            paOrigin: PaOriginType.WebApp,
            portal: portalData?.portal,
            onClickConfigOverride: portalData?.onClickConfigOverride,
            otherDefaultFields: {
              status: AuthorizationStatusEnum.Draft,
              HCPCSCodes: portalAuth.HCPCSCodes,
            },
            account: currentAccount,
            config,
            patientId: finalPatientId,
            createAuthorization,
            alert,
            isBlendedPortalWorkflow: enableBlendedPortalWorkflow,
            portalAuth,
          });

          if (!resDraftedPA?.id || !finalPatientId)
            return alert.error("Failed to create authorization");

          await updateAuthorization({
            variables: {
              id: parseInt(resDraftedPA?.id),
              patch: {
                InsuranceCompanyId:
                  +formData.patient.primaryInsurance.InsuranceCompanyId,
                config: {
                  [config.DEFAULT_FIELDS.PATIENT_MEMBER_ID.key]:
                    formData.patient.primaryInsurance.memberId,
                  [config.DEFAULT_FIELDS.INSURANCE_COMPANY.key]:
                    formData.insuranceCompanyName,
                  [config.DEFAULT_FIELDS.SUBSCRIBER_RELATIONSHIP.key]:
                    formData.patient.primaryInsurance.relationshipToSubscriber,
                },
              },
            },
          });

          await writeAuthResToPatient({
            variables: {
              id: +resDraftedPA?.id,
              patientId: +finalPatientId,
            },
          });

          const res = await setPrescriberOnAuth({
            variables: {
              authorizationId: +resDraftedPA?.id,
              prescriberId: +formData.PrescriberId,
            },
          });

          draftedPA = res?.data?.setPrescriberOnAuth;
        }

        if (!portalData?.portal?.isLegacy && draftedPA) {
          sendMsgToBgScript({
            type: "PortalAuthorizationData",
            data: {
              url: portalData?.portal?.loginUrl ?? "about:blank",
              draftedPA,
            },
          });
        }
      }),
      500
    ),
    [
      methods,
      currentAccount,
      createAuthorization,
      updateAuthorization,
      setPrescriberOnAuth,
    ]
  );

  useEffect(() => {
    const webExtensionPubSub = new WebExtensionPubSub();
    webExtensionPubSub.addListener((msg) => {
      switch (msg.data?.type) {
        case "PortalLaunchSuccess":
          window.location.reload();
          history.push("/");
          break;
        default:
          break;
      }
    });
    return () => webExtensionPubSub.removeListeners();
  }, [history]);

  return (
    <Stack>
      <Box p={2} pb={4}>
        <Typography variant="h5" sx={{ pb: 1 }}>
          Portal Prior Authorization
        </Typography>
        {portalData?.portal?.title && (
          <ExtensionAlertBanner
            isDownloadExtensionBanner={false}
            supportsAutofill={false}
            portalName={portalData?.portal?.title}
          />
        )}
        <FormProvider {...methods}>
          <EnrollmentSection title="Patient">
            <PatientBlock required />
          </EnrollmentSection>
          <EnrollmentSection title="Insurance">
            <EdiInsuranceBlock required />
          </EnrollmentSection>
          <EnrollmentSection title={HeaderTitle.ServicingProvider}>
            <PaProviderBlock required label="Servicing Provider" />
          </EnrollmentSection>
          <EnrollmentSection title="Service">
            <ServiceCodeDataWrapper
              name="services"
              apolloClient={apolloClient}
              disabled={false}
            />
          </EnrollmentSection>
          <LeftRightCenterAll sx={{ maxWidth: 600 }}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={!isValid}
            >
              Continue To Portal
            </Button>
          </LeftRightCenterAll>
        </FormProvider>
      </Box>
    </Stack>
  );
};
