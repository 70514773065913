import { useEffect, useState } from "react";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import { NpiCaptureSource, Prescriber } from "@samacare/graphql";
import { usePrescriber } from "@samacare/hooks-data";
import { SamaDialog } from "@samacare/component";
import { SearchByNpiPane } from "./SearchByNpiPane";
import { PrescriberEditPane } from "./PrescriberEditPane";
import { SearchErrorPane } from "./SearchErrorPane";

const enum DialogPane {
  SearchByNpi = "SearchByNpi",
  SearchError = "SearchError",
  Edit = "Edit",
}

export interface UpsertPrescriberDialogProps {
  open: boolean;
  apolloClient: object; // Couldn't keep Typescript happy on the calling end when given the proper ApolloClient type
  PrescriberId: number | string | undefined | null;
  isSelectionPhase?: boolean;
  onClose: (prescriber?: Prescriber | null) => void;
}
export const UpsertPrescriberDialog: React.FC<UpsertPrescriberDialogProps> = (
  props,
) => {
  const [pane, setPane] = useState(DialogPane.Edit);
  const [searchErrorMessage, setSearchErrorMessage] = useState<string | null>(
    null,
  );
  const [unfoundNpi, setUnfoundNpi] = useState<string | null>(null);
  const [prescriber, setPrescriber] = useState<Prescriber | null>(null);
  const [isFoundExisting, setIsFoundExisting] = useState(false);

  const { prescriber: existingPrescriber } = usePrescriber(props.PrescriberId);
  useEffect(() => {
    if (existingPrescriber) {
      setPrescriber(existingPrescriber);
    }
  }, [existingPrescriber]);

  useEffect(() => {
    if (props.open) {
      if (props.PrescriberId) {
        setPane(DialogPane.Edit);
      } else {
        setPrescriber({} as Prescriber);
        setPane(DialogPane.SearchByNpi);
      }
    }
  }, [props.PrescriberId, props.open]);

  const handleFound = (p: Prescriber) => {
    setIsFoundExisting(p.id !== undefined);
    setUnfoundNpi(null);
    setPrescriber({ ...p, source: NpiCaptureSource.Api });
    setPane(DialogPane.Edit);
  };

  const handleSearchError = (errorMessage: string, NPI: string) => {
    setSearchErrorMessage(errorMessage);
    setUnfoundNpi(NPI);
    setPane(DialogPane.SearchError);
  };

  const handleManualAdd = () => {
    setIsFoundExisting(false);
    setPrescriber({
      source: NpiCaptureSource.Manual,
      NPI: unfoundNpi,
    } as Prescriber);
    setPane(DialogPane.Edit);
  };

  const handleBack = () => {
    setPane(DialogPane.SearchByNpi);
  };

  useEffect(() => {
    if (props.open) {
      setIsFoundExisting(false);
    }
  }, [props.open]);

  return (
    <ApolloProvider client={props.apolloClient as ApolloClient<object>}>
      <SamaDialog open={props.open} onClose={() => props.onClose()}>
        {pane === DialogPane.SearchByNpi && (
          <SearchByNpiPane
            onClose={() => props.onClose()}
            onFound={handleFound}
            onError={handleSearchError}
          />
        )}
        {pane === DialogPane.SearchError && (
          <SearchErrorPane
            onClose={() => props.onClose()}
            onBack={() => setPane(DialogPane.SearchByNpi)}
            onAddManually={handleManualAdd}
            errorMessage={searchErrorMessage}
          />
        )}
        {pane === DialogPane.Edit && (
          <PrescriberEditPane
            prescriber={prescriber}
            isFoundExisting={isFoundExisting}
            isSelectionPhase={props.isSelectionPhase}
            onBack={handleBack}
            onClose={(p) => props.onClose(p)}
          />
        )}
      </SamaDialog>
    </ApolloProvider>
  );
};
