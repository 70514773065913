import _ from "lodash";
import * as React from "react";
import { Flex, Box } from "@@ui-kit";
import BaseSelect, { Props } from "react-select";
import { AuthorizationStatusEnum, Account, Portal } from "@samacare/graphql";
import { styled } from "@samacare/design/core/styles";

import { Action } from "../reducer";
import { useConfig } from "../../../hooks";

type AccountOption = {
  label: string;
  value: string;
};

type AuthorizationFiltersStatusOptions = {
  label: string;
  value: AuthorizationStatusEnum | null;
};

interface SelectProps<OptionType, IsMultiType extends boolean>
  extends Props<OptionType, IsMultiType> {}

const Select = styled(BaseSelect)`
  width: 185px;
` as <OptionType, IsMultiType extends boolean>(
  props: SelectProps<OptionType, IsMultiType>
) => JSX.Element;

const FilterContainer = styled(Box)`
  align-items: center;
  margin-right: 8px;
  margin-top: 12px;
`;

export type AuthorizationsFiltersProps = {
  currentAccount: Account;
  expirationDate: { offset: number; unit: "days" | "weeks" } | null;
  filterByExpired: boolean;
  filterByUpcomingDateOfServiceDays: string | null;
  insuranceCompanies: { id: string; name: string; isArchived: boolean }[];
  insuranceCompany: string | null;
  location: string | null;
  locations: { id: string; name: string }[];
  onChange: (action: Action) => void;
  portalId: string | null;
  portals: Portal[];
  prescriber: string | null;
  prescribers: {
    id: string;
    firstName?: string | null;
    label?: string | null;
    lastName?: string | null;
  }[];
  accounts: Account[];
  accountId: string | null;
  status: string | null;
  statuses: AuthorizationFiltersStatusOptions[];
};

function loadUserFilters(
  accounts: Account[],
  currentAccount: Account
): AccountOption[] {
  const results = accounts
    .sort((a, b) => {
      const nameA = `${a.firstName ?? ""} ${a.lastName ?? ""}`.toLowerCase(); // ignore upper and lowercase
      const nameB = `${b.firstName ?? ""} ${b.lastName ?? ""}`.toLowerCase(); // ignore upper and lowercase
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    })
    .reduce<AccountOption[]>((acc, account) => {
      if (account.firstName == null || account.lastName == null) return acc;
      if (!currentAccount.isSamaUser && account.isSamaUser) return acc;

      const label = account.isSystemUser
        ? "Unassigned"
        : `${account.firstName} ${account.lastName}`;

      const value = account.id;

      if (account.id === currentAccount.id) {
        acc.unshift({ label, value });
      } else {
        acc.push({ label, value });
      }

      return acc;
    }, []);

  return [
    {
      label: "All Assignees",
      value: "all",
    },
    ...results,
  ];
}

export const AuthorizationsFilters: React.VoidFunctionComponent<
  AuthorizationsFiltersProps
> = ({
  currentAccount,
  expirationDate,
  filterByExpired,
  filterByUpcomingDateOfServiceDays,
  insuranceCompanies,
  insuranceCompany,
  location,
  locations,
  onChange,
  portalId,
  portals,
  prescriber,
  prescribers,
  accounts,
  accountId,
  status,
  statuses: allStatuses,
}) => {
  const config = useConfig();
  const isNovartis = Boolean(
    currentAccount?.institution?.featureFlags?.Novartis
  );
  const showDOSFilters = !isNovartis && !filterByExpired;
  const showStatusFilters = !filterByExpired;
  const showExpirationFilters = filterByExpired;

  const statuses = [
    { value: null, label: "Any Status" },
    ...(isNovartis
      ? allStatuses
          .map((item) =>
            item.value === "sent"
              ? {
                  value: item.value,
                  label: "Sent to payer",
                }
              : item
          )
          .filter((item) =>
            config.CONSTANTS.NOVARTIS_STATUSES.includes(item.value ?? "")
          )
      : allStatuses
    ).sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    }),
  ];

  const userFilterOptions = [...loadUserFilters(accounts, currentAccount)];

  const userFilterValue = userFilterOptions.find(
    (option) => option.value === accountId
  ) ?? {
    label: "All Assignees",
    value: "all",
  };

  const insuranceFilterOptions = [
    { value: null, label: "All Payers" },
    ..._.map(insuranceCompanies, (option) => ({
      value: option.id,
      label: option.name,
    })),
  ];
  const insuranceFilterValue =
    insuranceFilterOptions.find(
      (option) => option.value === insuranceCompany
    ) ?? null;

  const prescriberIdOptions = [
    { value: null, label: "All Prescribers" },
    ..._.map(
      _.sortBy(prescribers, "lastName"),
      ({ id, firstName, lastName, label }) => ({
        value: id,
        label:
          label != null && label !== ""
            ? label
            : `${lastName ?? ""}, ${firstName ?? ""}`,
      })
    ),
  ];
  const prescriberIdValue =
    prescriberIdOptions.find((option) => option.value === prescriber) ?? null;

  const locationIdOptions = [
    { value: null, label: "All Locations" },
    ..._.map(_.sortBy(locations, "name"), ({ id, name }) => ({
      value: id,
      label: name,
    })),
  ];
  const locationIdValue =
    locationIdOptions.find((option) => option.value === location) ?? null;

  const expirationDateOptions = [
    {
      label: config.CONSTANTS.EXPIRATION_DATE_FILTERS.NOW.label,
      value: {
        unit: config.CONSTANTS.EXPIRATION_DATE_FILTERS.NOW.unit,
        offset: config.CONSTANTS.EXPIRATION_DATE_FILTERS.NOW.offset,
      },
    },
    ...Object.entries(config.CONSTANTS.EXPIRATION_DATE_FILTERS)
      .filter(([key]) => key !== "NOW")
      .map(([, value]) => value)
      .map((value) => ({
        label: value.label,
        value: {
          offset: value.offset,
          unit: value.unit,
        },
      })),
  ];
  const expirationDateValue =
    expirationDateOptions.find((option) => {
      return (
        option.value === expirationDate ||
        (option.value?.offset === expirationDate?.offset &&
          option.value?.unit === expirationDate?.unit)
      );
    }) ?? null;

  const dosOptions = [
    { label: "Any DoS", value: null },
    { label: "in Next 7 Days", value: "7" },
    { label: "in Next 14 Days", value: "14" },
    { label: "in Next 30 Days", value: "30" },
  ];
  const dosValue =
    dosOptions.find(
      (option) => option.value === filterByUpcomingDateOfServiceDays
    ) ?? null;

  const statusesValue =
    statuses.find((option) => option.value === status) ?? null;

  const portalOptions = [
    { value: null, label: "All Portals" },
    ..._.map(_.sortBy(portals, "title"), (option) => ({
      value: option.id,
      label: option.title,
    })),
  ];
  const portalValue =
    portalOptions.find((option) => option.value === portalId) ?? null;

  return (
    <Flex
      // Justify content against the bottom of the container so that the
      // bottom border on the selected tab aligns with this container's bottom
      // border
      flexGrow={1}
      flexWrap="wrap"
      mt={-1.5}
      justifyContent="flex-start"
    >
      <FilterContainer data-cy="controlFilterAssignee">
        {userFilterOptions.length === accounts.length + 1 && (
          <Select
            isMulti={false}
            name="assignee"
            onChange={(value) => {
              onChange({
                type: "accountId",
                value: value?.value ?? null,
              });
            }}
            options={userFilterOptions.map((selection) => {
              return {
                ...selection,
                label: selection.label,
                value: selection.value,
              };
            })}
            defaultValue={userFilterOptions[0]}
            value={userFilterValue}
          />
        )}
      </FilterContainer>
      <FilterContainer data-cy="controlFilterInsuranceCompany">
        <Select
          isClearable
          isMulti={false}
          name="insuranceCompanyId"
          onChange={(value) => {
            onChange({
              type: "insuranceCompanyId",
              value: value?.value ?? null,
            });
          }}
          options={insuranceFilterOptions}
          defaultValue={insuranceFilterOptions[0]}
          value={insuranceFilterValue}
        />
      </FilterContainer>

      {prescriberIdOptions.filter(({ value }) => value).length > 0 && (
        <FilterContainer data-cy="controlFilterPrescriber">
          <Select
            isClearable
            isMulti={false}
            name="prescriberId"
            onChange={(value) =>
              onChange({ type: "prescriberId", value: value?.value ?? null })
            }
            options={prescriberIdOptions}
            defaultValue={prescriberIdOptions[0]}
            value={prescriberIdValue}
          />
        </FilterContainer>
      )}
      {locationIdOptions.filter(({ value }) => value).length > 0 && (
        <FilterContainer data-cy="controlFilterLocation">
          <Select
            isClearable
            isMulti={false}
            name="locationId"
            onChange={(value) =>
              onChange({ type: "locationId", value: value?.value ?? null })
            }
            options={locationIdOptions}
            defaultValue={locationIdOptions[0]}
            value={locationIdValue}
          />
        </FilterContainer>
      )}
      {showExpirationFilters && <div style={{ width: "10px" }} />}
      {showExpirationFilters && (
        <FilterContainer data-cy="controlFilterExpirationDate">
          <Select
            isClearable
            isMulti={false}
            name="expirationDate"
            onChange={(value) =>
              onChange({
                type: "expirationDate",
                value: value?.value ?? null,
              })
            }
            options={expirationDateOptions}
            defaultValue={expirationDateOptions[0]}
            value={expirationDateValue}
          />
        </FilterContainer>
      )}
      {showDOSFilters && (
        <FilterContainer data-cy="controlFilterUpcomingDateOfService">
          <Select
            isClearable
            isMulti={false}
            name="filterByUpcomingDateOfServiceDays"
            onChange={(value) =>
              onChange({
                type: "filterByUpcomingDateOfServiceDays",
                value: value?.value ?? null,
              })
            }
            options={dosOptions}
            defaultValue={dosOptions[0]}
            value={dosValue}
          />
        </FilterContainer>
      )}
      {showStatusFilters && (
        <FilterContainer data-cy="controlFilterStatus">
          <Select
            isClearable
            isMulti={false}
            name="status"
            onChange={(value) =>
              onChange({
                type: "status",
                value: value?.value ?? null,
              })
            }
            options={statuses.filter(
              ({ value }) =>
                value != null &&
                config.CONSTANTS.FILTERABLE_STATUSES.includes(value)
            )}
            defaultValue={statuses[0]}
            value={statusesValue}
          />
        </FilterContainer>
      )}
      {portalOptions.filter(({ value }) => value).length > 0 && (
        <FilterContainer data-cy="controlFilterPortal">
          <Select
            isClearable
            isMulti={false}
            name="portal"
            onChange={(value) =>
              onChange({
                type: "portalId",
                value: value?.value ?? null,
              })
            }
            options={portalOptions}
            defaultValue={portalOptions[0]}
            value={portalValue}
          />
        </FilterContainer>
      )}
    </Flex>
  );
};
