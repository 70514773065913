import _ from "lodash";

import { AutocompleteOnChangeType } from "@samacare/design/core/Autocomplete/Autocomplete";
import { Prescriber } from "@samacare/graphql";
import {
  AutocompleteField,
  useFormContext,
  type AutocompleteFieldProps,
} from "@samacare/form2";
import { createFilterOptions } from "@mui/base";

type PrescriberAutocompleteProps = Omit<
  AutocompleteFieldProps<Prescriber, false, false, false>,
  "name" | "options"
>;

export type ProviderAutocompleteInternalFunctionalProps = {
  disabled?: boolean;
  required?: boolean;
  PrescriberAutocompleteInputProps?: PrescriberAutocompleteProps;
  chooseDisabled?: boolean;
  placeholder?: string;
  filterSelectedOptions?: boolean;
  name: string;
  label?: string;
  onChange: AutocompleteOnChangeType<Prescriber, false, false, false>;
  onNew?: () => void;
  noOptionsText?: React.ReactNode;
  value?: Prescriber | null;
} & (
  | {
      onInputChange: (
        event: React.SyntheticEvent,
        value: string,
        reason: string,
      ) => void;
      inputValue: string;
      value: Prescriber | null;
      open: boolean;
      onOpen: () => void;
      onClose: () => void;
    }
  | {
      onInputChange?: never;
      inputValue?: never;
      value?: never;
      open?: never;
      onOpen?: never;
      onClose?: never;
    }
);

export type ProviderAutocompleteFunctionalProps =
  ProviderAutocompleteInternalFunctionalProps & {
    apolloClient: object; // Couldn't keep Typescript happy on the calling end when given the proper ApolloClient type
  };

export type ProviderAutocompleteDataProps = {
  providers: Prescriber[];
  loading: boolean;
};

export type ProviderAutocompleteAllProps =
  ProviderAutocompleteInternalFunctionalProps & ProviderAutocompleteDataProps;

const defaultProps: ProviderAutocompleteInternalFunctionalProps = {
  disabled: false,
  PrescriberAutocompleteInputProps: {},
  chooseDisabled: false,
  name: "PrescriberBlock",
  onChange: () => {},
  onNew: () => {},
  noOptionsText: "No options",
  inputValue: undefined,
  onInputChange: undefined,
  value: undefined,
};

const filter = createFilterOptions<Prescriber>();

export const ProviderAutocomplete: React.FC<ProviderAutocompleteAllProps> = (
  rawProps,
) => {
  const {
    disabled,
    required,
    PrescriberAutocompleteInputProps,
    chooseDisabled,
    name,
    providers,
    onChange,
    onNew,
    label,
    noOptionsText,
    inputValue,
    onInputChange,
    value,
    open,
    onClose,
    onOpen,
  } = _.merge(
    _.cloneDeep(defaultProps),
    rawProps,
  ) as Required<ProviderAutocompleteAllProps>;

  const { trigger } = useFormContext();

  const cannotChoose = disabled || chooseDisabled;

  return (
    <AutocompleteField<Prescriber, false, false, false>
      disablePortal
      name={name}
      data-cy="controlProviderAutocomplete"
      options={providers}
      placeholder={cannotChoose ? "" : "Choose..."}
      filterSelectedOptions
      autoHighlight={PrescriberAutocompleteInputProps.autoHighlight ?? true}
      filterOptions={(opts, params) => {
        const filtered = filter(opts, params);

        if (params.inputValue !== "" && filtered.length === 0) {
          filtered.push({
            id: "NEW",
            firstName: "Add Provider",
          } as Prescriber);
        }

        return filtered;
      }}
      getOptionLabel={(option: Prescriber) =>
        option?.firstName == null
          ? "Unknown"
          : `${option?.firstName} ${option?.lastName ?? ""}${
              option?.label ? ` (${option?.label})` : ""
            }`
      }
      value={value}
      noOptionsText={noOptionsText}
      onChange={async (e, v, reason) => {
        if (v?.id === "NEW") {
          onNew();
        } else if (onChange) {
          onChange(e, v, reason);
          await trigger();
        }
      }}
      disabled={cannotChoose}
      required={required}
      inputValue={inputValue}
      label={label}
      onInputChange={onInputChange}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      {...PrescriberAutocompleteInputProps}
    />
  );
};
