import { VFC, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { FormProvider, PaProviderBlock, useForm } from "@samacare/form";
import { Authorization, Prescriber } from "@samacare/graphql";
import { ProviderType } from "../types/types";

import { useSet } from "../../../../hooks/useSet";
import {
  setPrescriberOnAuthMutation,
  withUpdateFormDetailsMutation,
} from "../../../../graphql/Authorization";
import { SegmentTitle } from "../../../Segment/SegmentTitle";
import { Box, Typography } from "@samacare/design";
import { HeaderTitle } from "../../../../constants/HeaderTitle";
import { useSetRequired } from "../../../../hooks/useSetRequired";
import { useHighlightRequiredFields } from "../../../../hooks/useHighlightRequiredFields";
import { useUnsetRequired } from "../../../../hooks/useUnsetRequired";
import { useTheme } from "@samacare/design/core/styles";
import { LeftRightCenterV } from "@samacare/component";

const { DEFAULT_FIELDS } = window.CONFIG;
const { PRESCRIBER, REFERRING_PROVIDER } =
  window.CONFIG.CONSTANTS.PRESCRIBER_TYPES;

const PRESCRIBER_ID = "PrescriberId";
const PRESCRIBER_STR = "prescriber";

export interface PrescriberDetailsProps {
  authorization: Authorization;
  disabled: boolean;
  providerType: ProviderType;
  results: Record<string, string>;
  trackUpsert: (
    prescriber: Prescriber,
    isPrescriber: boolean,
    providerType: string
  ) => void;
}

const PrescriberDetails: VFC<PrescriberDetailsProps> = ({
  providerType = PRESCRIBER,
  disabled,
  authorization,
  results,
  trackUpsert,
}) => {
  const theme = useTheme();
  const set = useSet();
  const setRequired = useSetRequired();
  const unsetRequired = useUnsetRequired();
  const highlightIfMissing = useHighlightRequiredFields();
  const [updateFormDetails] = useMutation(withUpdateFormDetailsMutation);
  const [setPrescriberOnAuth] = useMutation(setPrescriberOnAuthMutation);

  const methods = useForm();
  const { watch, setValue, setError, clearErrors, formState } = methods;

  const PrescriberId = watch(PRESCRIBER_ID);
  const prescriber = watch(PRESCRIBER_STR);

  const isPrescriber = providerType === PRESCRIBER;

  const sectionTitle = isPrescriber
    ? HeaderTitle.ServicingProvider
    : HeaderTitle.ReferringProvider;

  useEffect(() => {
    if (isPrescriber) {
      setRequired(DEFAULT_FIELDS.PRESCRIBER_NPI.key);
    }
    return () => {
      unsetRequired(DEFAULT_FIELDS.PRESCRIBER_NPI.key);
    };
  }, [setRequired, unsetRequired]);

  useEffect(() => {
    if (highlightIfMissing && !PrescriberId) {
      if (isPrescriber) {
        setError(DEFAULT_FIELDS.PRESCRIBER_NPI.key, {
          type: "required",
          message: "Servicing Provider is required",
        });
      }
    } else if (!highlightIfMissing) {
      if (isPrescriber) {
        clearErrors(DEFAULT_FIELDS.PRESCRIBER_NPI.key);
      }
    }
  });

  useEffect(() => {
    if (!authorization.config) return;
    if (isPrescriber) {
      if (
        authorization.config.PRESCRIBER_FIRST_NAME ||
        authorization.config.PRESCRIBER_NPI
      ) {
        const pres = {
          id: authorization.PrescriberId,
          firstName: authorization.config.PRESCRIBER_FIRST_NAME,
          lastName: authorization.config.PRESCRIBER_LAST_NAME,
          NPI: authorization.config.PRESCRIBER_NPI,
          TIN: authorization.config.PRESCRIBER_TIN,
          specialtyDescription: authorization.config.PRESCRIBER_SPECIALTY,
          specialtyCode: authorization.config.PRESCRIBER_SPECIALTY_CODE,
          DEA: authorization.config.PRESCRIBER_DEA_NUMBER,
          licenseNumber: authorization.config.PRESCRIBER_LICENSE_NUMBER,
          label: authorization.config.PRESCRIBER_LABEL,
        };
        setValue(PRESCRIBER_ID, authorization.PrescriberId, {
          shouldDirty: false,
        });
        setValue(PRESCRIBER_STR, pres, { shouldDirty: false });
        set({
          [DEFAULT_FIELDS.PRESCRIBER_NPI.key]:
            authorization.config.PRESCRIBER_NPI,
        });
      } else {
        setValue(PRESCRIBER_ID, null, { shouldDirty: false });
        setValue(PRESCRIBER_STR, null, { shouldDirty: false });
        set({ [DEFAULT_FIELDS.PRESCRIBER_NPI.key]: null });
      }
    }
  }, [authorization.PrescriberId]);

  useEffect(() => {
    if (!authorization.config) return;
    if (!isPrescriber) {
      if (
        authorization.config.REFERRING_PROVIDER_FIRST_NAME ||
        authorization.config.REFERRING_PROVIDER_NPI
      ) {
        const pres = {
          id: authorization.ReferringProviderId,
          firstName: authorization.config.REFERRING_PROVIDER_FIRST_NAME,
          lastName: authorization.config.REFERRING_PROVIDER_LAST_NAME,
          NPI: authorization.config.REFERRING_PROVIDER_NPI,
          TIN: authorization.config.REFERRING_PROVIDER_TIN,
          specialtyDescription:
            authorization.config.REFERRING_PROVIDER_SPECIALTY,
          specialtyCode: authorization.config.REFERRING_PROVIDER_SPECIALTY_CODE,
          DEA: authorization.config.REFERRING_PROVIDER_DEA_NUMBER,
          licenseNumber: authorization.config.REFERRING_PROVIDER_LICENSE_NUMBER,
          label: authorization.config.REFERRING_PROVIDER_LABEL,
        };
        setValue(PRESCRIBER_ID, authorization.ReferringProviderId, {
          shouldDirty: false,
        });
        setValue(PRESCRIBER_STR, pres, { shouldDirty: false });
        set({
          [DEFAULT_FIELDS.REFERRING_PROVIDER_NPI.key]:
            authorization.config.REFERRING_PROVIDER_NPI,
        });
      } else {
        setValue(PRESCRIBER_ID, null, { shouldDirty: false });
        setValue(PRESCRIBER_STR, null, { shouldDirty: false });
        set({ [DEFAULT_FIELDS.REFERRING_PROVIDER_NPI.key]: null });
      }
    }
  }, [authorization.ReferringProviderId]);

  const handleAfterSelection = async (p: Prescriber | undefined) => {
    // The selector will update the prescriber set for the form, which we want to avoid for
    // setting other types of providers such as referring provider
    if (p) {
      switch (providerType) {
        case REFERRING_PROVIDER:
          set({
            [DEFAULT_FIELDS.REFERRING_PROVIDER_FIRST_NAME.key]: p?.firstName,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_LAST_NAME.key]: p?.lastName,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_NPI.key]: p?.NPI,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_TIN.key]: p?.TIN,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_SPECIALTY.key]:
              p?.specialtyDescription,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_SPECIALTY_CODE.key]:
              p?.specialtyCode,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_DEA_NUMBER.key]: p?.DEA,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_LICENSE_NUMBER.key]:
              p?.licenseNumber,
          });
          break;
        default:
          await setPrescriberOnAuth({
            variables: {
              authorizationId: authorization.id,
              prescriberId: p.id,
            },
          });
          await updateFormDetails({
            variables: {
              id: authorization.id,
              details: { prescriberId: p.id },
            },
          });
      }

      // REALLY JANKY FIX
      // We save the authorization because there are some cases when the user doesn't have a phone saved
      // And setPrescriberOnAuth will clear out the number they input (because it refetches the auth + removes front end cached data)s
      trackUpsert(p, true, providerType);
      set({
        [DEFAULT_FIELDS.REQUESTED_BY_PHONE.key]:
          results[DEFAULT_FIELDS.REQUESTED_BY_PHONE.key],
      });
    }
  };

  useEffect(() => {
    if (!formState.dirtyFields.prescriber) return;
    if (prescriber) {
      void handleAfterSelection(prescriber);
    } else if (!prescriber) {
      if (isPrescriber) {
        set({
          [DEFAULT_FIELDS.PRESCRIBER_FIRST_NAME.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_LAST_NAME.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_NPI.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_TIN.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_SPECIALTY.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_SPECIALTY_CODE.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_DEA_NUMBER.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_LICENSE_NUMBER.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_LABEL.key]: "",
        });
      } else {
        set({
          [DEFAULT_FIELDS.REFERRING_PROVIDER_FIRST_NAME.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_LAST_NAME.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_NPI.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_TIN.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_SPECIALTY.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_SPECIALTY_CODE.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_DEA_NUMBER.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_LICENSE_NUMBER.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_LABEL.key]: "",
        });
      }
    }
  }, [prescriber, formState.dirtyFields.prescriber]);

  return (
    <Box p="5px">
      <SegmentTitle>
        <LeftRightCenterV>
          {sectionTitle}
          {isPrescriber && (
            <Typography color={theme.palette.error.main}>*</Typography>
          )}
        </LeftRightCenterV>
      </SegmentTitle>
      <FormProvider {...methods}>
        <form>
          <PaProviderBlock
            sx={{ mt: 2 }}
            disabled={disabled}
            name="PRESCRIBER_NPI"
          />
        </form>
      </FormProvider>
    </Box>
  );
};

export default PrescriberDetails;
