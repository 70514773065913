import { useState, useEffect } from "react";

import { InsuranceBlock, useForm, FormProvider } from "@samacare/form";
import { Patient } from "@samacare/graphql";
import { Stack, Typography, LoadingButton } from "@samacare/design/core";
import { useTheme } from "@samacare/design/core/styles";
import { usePatientTreatmentsContext } from "./PatientTreatmentsProvider";
import { useUpdatePatient } from "@@hooks/useUpdatePatient";

interface PatientTreatmentInsuranceInfoSectionProps {
  patientDetails: Patient | null;
  refreshingEligibilityCheck: boolean;
  runEligibilityCheck: () => Promise<void>;
}

export const PatientTreatmentInsuranceInfoSection: React.FC<
  PatientTreatmentInsuranceInfoSectionProps
> = ({ patientDetails, refreshingEligibilityCheck, runEligibilityCheck }) => {
  const theme = useTheme();
  const { activeRow } = usePatientTreatmentsContext();
  const updatePatient = useUpdatePatient();

  const methods = useForm<{
    patient: Patient | null;
  }>({
    defaultValues: {
      patient: {
        primaryInsurance: {
          InsuranceCompanyId:
            patientDetails?.primaryInsurance?.InsuranceCompanyId,
          insuranceState: patientDetails?.primaryInsurance?.insuranceState,
          memberId: patientDetails?.primaryInsurance?.memberId,
          planType: patientDetails?.primaryInsurance?.planType,
        },
      },
    },
  });
  const { patient } = methods.watch();

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    setDisabled(
      patient?.primaryInsurance?.InsuranceCompanyId == null ||
        patient?.primaryInsurance?.insuranceState == null ||
        patient?.primaryInsurance?.memberId == null
    );
  }, [
    patient?.primaryInsurance?.InsuranceCompanyId,
    patient?.primaryInsurance?.insuranceState,
    patient?.primaryInsurance?.memberId,
  ]);

  //isPolicyValid means
  //1. supportsCoverageCheck
  //2. if supportsCoverageCheck is false, must include all insurance details (memberId, insuranceCompany, insuranceState)
  const isPolicyValid =
    patientDetails?.primaryInsurance?.supportsCoverageCheck ||
    (patientDetails?.primaryInsurance?.isValid &&
      patientDetails?.primaryInsurance?.insuranceState != null);

  if (isPolicyValid) {
    return (
      <Stack direction="row" spacing={4}>
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2" color={theme.palette.text.primary}>
            MRN#
          </Typography>
          <Typography variant="body2" color={theme.palette.text.primary}>
            {activeRow?.primaryInsurance?.memberId ?? "-"}
          </Typography>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2" color={theme.palette.text.primary}>
            Primary Payor
          </Typography>
          <Typography variant="body2" color={theme.palette.text.primary}>
            {activeRow?.primaryInsurance?.insuranceCompany?.name ?? "-"}
          </Typography>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2" color={theme.palette.text.primary}>
            Insurance State
          </Typography>
          <Typography variant="body2" color={theme.palette.text.primary}>
            {activeRow?.primaryInsurance?.insuranceState ?? "-"}
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <FormProvider {...methods}>
      <form id="patientInsuranceForm" name="patientInsuranceForm">
        <Stack direction="column" spacing={1}>
          <InsuranceBlock required />
          <LoadingButton
            data-cy="actionRunEligibilityCheck"
            type="button"
            onClick={async () => {
              await updatePatient({
                id: patientDetails ? +patientDetails.id : undefined,
                patch: methods.getValues().patient ?? {},
              });
              await runEligibilityCheck();
            }}
            loading={refreshingEligibilityCheck}
            disabled={disabled}
            variant="contained"
            sx={{ width: "fit-content" }}
          >
            Continue
          </LoadingButton>
        </Stack>
      </form>
    </FormProvider>
  );
};
