import {
  Box,
  Typography,
  Stack,
  Alert,
  CircularProgress,
} from "@samacare/design/core";
import { useTheme, alpha } from "@samacare/design/core/styles";
import { useConfig } from "../../hooks/config";
import { Patient } from "@samacare/graphql";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { useCurrentAccount } from "../../graphql/Account";
import { StatusDetailsForTreatmentView } from "./StatusDetailsForTreatmentView";
import { useEffect } from "react";

interface InsuranceCoverageCheckProps {
  patient: Patient | null;
  isLoading: boolean;
  onRunClick: () => void;
  disabled?: boolean;
  eligibilityCheckError: boolean;
  autoRun?: boolean;
}

const CoverageCheckAlert = ({
  children,
  sxOverride,
}: {
  children: React.ReactNode;
  sxOverride?: any;
}) => {
  const theme = useTheme();

  return (
    <Alert
      severity="info"
      icon={false}
      sx={{
        justifyContent: "center",
        backgroundColor: alpha(theme.palette.primary.main, 0.04),
        "& .MuiAlert-message": {
          width: "100%",
        },
        ...sxOverride,
      }}
    >
      {children}
    </Alert>
  );
};

export const InsuranceCoverageCheckWithAlert = ({
  patient,
  isLoading,
  onRunClick,
  disabled = false,
  eligibilityCheckError,
  autoRun = false,
}: InsuranceCoverageCheckProps) => {
  const config = useConfig();
  const [currentAccount] = useCurrentAccount();
  const isEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableEligibilityCheck
  );

  const latestCoverageCheck = patient?.primaryInsurance?.latestCoverageCheck;

  const invalidOrNoPriorRecord =
    (!latestCoverageCheck || !latestCoverageCheck?.isValid) &&
    !eligibilityCheckError;

  useEffect(() => {
    // we want to run the check automatically if the patient's primary insurance and insurance state combination supports BV lite and there is no prior record
    if (
      invalidOrNoPriorRecord &&
      patient?.primaryInsurance?.supportsCoverageCheck &&
      autoRun
    ) {
      onRunClick();
    }
  }, [
    autoRun,
    invalidOrNoPriorRecord,
    onRunClick,
    patient?.primaryInsurance?.latestCoverageCheck,
    patient?.primaryInsurance?.supportsCoverageCheck,
  ]);

  //if the patient's primary insurance and insurance state combination does not support BV lite
  //or BV lite is not enabled
  if (!isEnabled || !patient?.primaryInsurance?.supportsCoverageCheck) {
    return <Box />;
  }

  if (isLoading) {
    return (
      <CoverageCheckAlert>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={16} />
          <Typography variant="body2" color="textPrimary">
            Checking patient coverage
          </Typography>
        </Stack>
      </CoverageCheckAlert>
    );
  }

  return (
    <CoverageCheckAlert sxOverride={{ justifyContent: "flex-start" }}>
      <Stack spacing={1} direction="column">
        <StatusDetailsForTreatmentView
          patient={patient}
          currentAccount={currentAccount}
          latestCoverageCheck={latestCoverageCheck}
          eligibilityCheckError={eligibilityCheckError}
          onRunClick={onRunClick}
          isLoading={isLoading}
          disabled={disabled}
        />
      </Stack>
    </CoverageCheckAlert>
  );
};
