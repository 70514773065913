import _ from "lodash";
import { useMutation } from "@apollo/client";

import {
  Stack,
  Typography,
  Paper,
  LoadingButton,
  Grid,
  Alert,
} from "@samacare/design/core";
import Chip from "@samacare/design/core/Chip";
import OpenInNewIcon from "@samacare/design/core/icons/OpenInNew";
import { useTheme } from "@samacare/design/core/styles";
import { usePatientTreatmentsContext } from "./PatientTreatmentsProvider";
import { useConfig } from "../../hooks/config";
import {
  Authorization,
  BenefitsVerificationBasic,
  NewEnrollmentObjectType,
  BenefitsVerificationOutcomeStatus,
  NewEnrollmentStatusEnumType,
} from "@samacare/graphql";
import { generatePath } from "react-router-dom";
import { CreateBenefitsVerificationMutation } from "@@generated/graphql";
import getAuthorizationRoute from "../../util/getAuthorizationRoute";
import ROUTE_PATHS from "../ROUTE_PATHS";
import CreateBenefitsVerificationMutationGql from "../BenefitsVerifications/graphql/CreateBenefitsVerification.gql";
import { ALL_BENEFITS_VERIFICATIONS_QUERY_NAME } from "../../graphql/BenefitsVerificationOutcome";
import { renderDate } from "./utils";
import NewPriorAuthButton from "@@components/NewPriorAuthButton";
import { EnhancedServicesIndicatorFull } from "@samacare/component";
import { useFeatureFlag } from "@@hooks/index";
import Add from "@samacare/design/core/icons/Add";

const serviceDetails = (
  serviceInfos: {
    label: string;
    value: string;
    color: string;
  }[]
) => {
  return (
    <Grid container>
      {serviceInfos.map(({ label, value, color }, i) => {
        if (label === "Status") {
          return (
            <Grid item xs={6} marginBottom={2} key={i}>
              <Typography variant="subtitle2">{label}</Typography>
              <Typography variant="subtitle2" color={color}>
                {value}
              </Typography>
            </Grid>
          );
        }
        return (
          <Grid item xs={6} marginBottom={2} key={i}>
            <Typography variant="subtitle2">{label}</Typography>
            <Typography variant="body2" color={color}>
              {value}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

const PatientTreatmentCardAuthDetail: React.FC<{
  auths: (Authorization | null)[];
  patientId: string;
  expiring: boolean;
}> = ({ auths, patientId, expiring }) => {
  const theme = useTheme();
  const config = useConfig();

  const latestAuth = _.compact(_.orderBy(auths, "updatedAt", "desc"))[0];

  const renderGrid = (auth: Authorization) => {
    const defaultColor = theme.palette.grey.A400;

    const renderDefaultViews = (statusColor: string) => {
      return serviceDetails([
        {
          label: "Status",
          value: _.startCase(auth.status.toLowerCase()),
          color: statusColor,
        },
        {
          label: "Last Updated",
          value: renderDate(auth.updatedAt),
          color: defaultColor,
        },
        {
          label: "Created At",
          value: renderDate(auth.createdAt),
          color: defaultColor,
        },
        {
          label: "Service Date",
          value: renderDate(auth.dateOfService),
          color: defaultColor,
        },
      ]);
    };

    const lastUpdatedInfo = [
      {
        label: "Last Updated",
        value: renderDate(auth.updatedAt),
        color: defaultColor,
      },
    ];

    if (auth.status === config.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION) {
      return serviceDetails([
        {
          label: "Status",
          value: _.startCase(auth.status.toLowerCase()),
          color: theme.palette.grey.A400,
        },
        ...lastUpdatedInfo,
        {
          label: "Created At",
          value: renderDate(auth.createdAt),
          color: defaultColor,
        },
        {
          label: "Service Date",
          value: renderDate(auth.dateOfService),
          color: defaultColor,
        },
      ]);
    } else if (config.CONSTANTS.APPROVED_STATUSES.includes(auth.status)) {
      return serviceDetails([
        {
          label: "Status",
          value: _.startCase(auth.status.toLowerCase()),
          color: theme.palette.success.main,
        },
        ...lastUpdatedInfo,
        {
          label: "Approved Start Date",
          value: auth.latestCorrespondence
            ? renderDate(auth.latestCorrespondence?.startDate)
            : "N/A",
          color: theme.palette.text.primary,
        },
        {
          label: "Approved End Date",
          value: auth.latestCorrespondence
            ? renderDate(auth.latestCorrespondence.endDate)
            : "N/A",
          color: theme.palette.text.primary,
        },
      ]);
    } else if (
      config.CONSTANTS.PROGRESS_AUTHORIZATION_STATUSES.includes(auth.status)
    ) {
      return renderDefaultViews(theme.palette.primary.main);
    } else if (config.CONSTANTS.AUTHORIZATION_STATUSES.DENIED === auth.status) {
      return renderDefaultViews(theme.palette.error.main);
    } else if (
      config.CONSTANTS.AUTHORIZATION_STATUSES.ACTION_REQUIRED === auth.status
    ) {
      return renderDefaultViews(theme.palette.warning.main);
    }

    return renderDefaultViews(defaultColor);
  };

  if (latestAuth == null) {
    return <Stack />;
  }

  return (
    <Stack direction="column" spacing={2} justifyContent="start">
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h6" color={theme.palette.primary.main}>
            Prior Authorization
          </Typography>
          <Chip
            label={_.capitalize(latestAuth.type?.toLowerCase())}
            size="small"
            sx={{ cursor: "pointer" }}
            color="default"
            variant="outlined"
          />
        </Stack>

        <LoadingButton
          onClick={() => {
            window.open(
              `${window.location.origin}/#${getAuthorizationRoute(
                latestAuth
              )}?step=${
                (
                  latestAuth.formDetails as {
                    currentStep: number;
                  } | null
                )?.currentStep?.toString() ?? "1"
              }&id=${latestAuth.id}`,
              "_blank"
            );
          }}
          endIcon={<OpenInNewIcon />}
          data-cy="actionViewAuthorizationFromTreatment"
        >
          <u>View</u>
        </LoadingButton>
      </Stack>

      <Grid container>{renderGrid(latestAuth)}</Grid>

      {expiring && (
        <NewPriorAuthButton patientId={patientId}>
          <LoadingButton
            variant="contained"
            size="small"
            sx={{ maxWidth: 250 }}
            data-cy="actionRenewAuthorizationFromTreatment"
          >
            Renew Authorization
          </LoadingButton>
        </NewPriorAuthButton>
      )}

      {auths.length > 1 && (
        <LoadingButton
          size="small"
          onClick={() => {
            window.open(
              `${window.location.origin}/#${generatePath(
                `${ROUTE_PATHS.AUTHORIZATION_LIST_FILTERED.path}?firstName=${latestAuth.patient?.firstName}&lastName=${latestAuth.patient?.lastName}`
              )}`,
              "_blank"
            );
          }}
          endIcon={<OpenInNewIcon />}
          sx={{ maxWidth: "fit-content", padding: 0 }}
          data-cy="actionViewAuthorizationsListFromTreatment"
        >
          <u>{`View ${auths.length - 1} more authorizations `}</u>
        </LoadingButton>
      )}
    </Stack>
  );
};

const PatientTreatmentCardBvDeteail: React.FC<{
  bvs: (BenefitsVerificationBasic | null)[];
  hasAuths: boolean;
  patientId: string;
}> = ({ bvs, hasAuths, patientId }) => {
  const theme = useTheme();

  const latestBv = _.compact(_.orderBy(bvs, "updatedAt", "desc"))[0];
  if (latestBv == null) {
    return <Stack />;
  }

  const renderGrid = (bv: BenefitsVerificationBasic) => {
    const defaultColor = theme.palette.grey.A400;
    const renderDefault = (statusColor: string) => {
      return serviceDetails([
        {
          label: "Status",
          value: _.startCase((bv.status ?? "Draft")?.toLowerCase()),
          color: statusColor,
        },
        {
          label: "Last Updated",
          value: renderDate(bv.updatedAt),
          color: defaultColor,
        },
        {
          label: "Created At",
          value: renderDate(bv.createdAt),
          color: defaultColor,
        },
        {
          label: "Payor",
          value: bv.insuranceCompanyName ?? "N/A",
          color: defaultColor,
        },
      ]);
    };

    switch (bv.status) {
      case BenefitsVerificationOutcomeStatus.Pending:
        return renderDefault(theme.palette.primary.main);
      case BenefitsVerificationOutcomeStatus.Success:
        return renderDefault(theme.palette.success.main);
      case BenefitsVerificationOutcomeStatus.Error:
        return renderDefault(theme.palette.error.main);
      default:
        return renderDefault(defaultColor);
    }
  };

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6" color={theme.palette.primary.main}>
          Benefit Verification
        </Typography>
        <LoadingButton
          onClick={() => {
            if (latestBv?.status && !latestBv?.isDraft) {
              window.open(
                `${window.location.origin}/#${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${latestBv?.id}`,
                "_blank"
              );
            } else {
              window.open(
                `${window.location.origin}/#${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/edit/${latestBv?.id}`,
                "_blank"
              );
            }
          }}
          endIcon={<OpenInNewIcon />}
          data-cy="actionViewBenefitsVerificationFromTreatment"
        >
          <u>View</u>
        </LoadingButton>
      </Stack>

      <Grid container>{renderGrid(latestBv)}</Grid>

      {bvs.length > 1 && (
        <LoadingButton
          size="small"
          onClick={() => {
            window.open(
              `${window.location.origin}/#${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}?quickFilters=${latestBv.patient?.firstName},${latestBv.patient?.lastName}`,
              "_blank"
            );
          }}
          endIcon={<OpenInNewIcon />}
          sx={{ maxWidth: "fit-content", padding: 0 }}
          data-cy="actionViewBenefitsVerificationsListFromTreatment"
        >
          <u>{`View ${bvs.length - 1} more benefit verifications`}</u>
        </LoadingButton>
      )}

      {!hasAuths && (
        <NewPriorAuthButton patientId={patientId}>
          <LoadingButton
            variant="contained"
            size="small"
            sx={{ maxWidth: 250 }}
            data-cy="actionStartAuthorizationFromTreatment"
          >
            New Authorization
          </LoadingButton>
        </NewPriorAuthButton>
      )}
    </Stack>
  );
};

const PatientTreatmentCardEnrollmentDetail: React.FC<{
  enrollments: (NewEnrollmentObjectType | null)[];
}> = ({ enrollments }) => {
  const theme = useTheme();

  const latestEnrollment = _.compact(
    _.orderBy(enrollments, "updatedAt", "desc")
  )[0];

  if (latestEnrollment == null) {
    return <Stack />;
  }

  const renderGrid = (enrollment: NewEnrollmentObjectType) => {
    const defaultColor = theme.palette.grey.A400;
    const renderDefault = (statusColor: string) => {
      return serviceDetails([
        {
          label: "Status",
          value: _.startCase(enrollment.status?.toLowerCase()),
          color: statusColor,
        },
        {
          label: "Last Updated",
          value: renderDate(enrollment.updatedAt),
          color: defaultColor,
        },
        {
          label: "Created At",
          value: renderDate(enrollment.createdAt),
          color: defaultColor,
        },
        {
          label: "Payor",
          value: enrollment.InsuranceCompany?.name ?? "N/A",
          color: defaultColor,
        },
      ]);
    };

    switch (enrollment.status) {
      case NewEnrollmentStatusEnumType.Completed:
        return renderDefault(theme.palette.success.main);
      case NewEnrollmentStatusEnumType.Submitted:
        return renderDefault(theme.palette.primary.main);
      default:
        return renderDefault(defaultColor);
    }
  };

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6" color={theme.palette.primary.main}>
          Enrollment
        </Typography>
        <LoadingButton
          onClick={() => {
            window.open(
              `${window.location.origin}/#${ROUTE_PATHS.ENROLLMENTS_CREATE.path}/details/${latestEnrollment?.id}`,
              "_blank"
            );
          }}
          endIcon={<OpenInNewIcon />}
          data-cy="actionViewEnrollmentFromTreatment"
        >
          <u>View</u>
        </LoadingButton>
      </Stack>

      <Grid container>{renderGrid(latestEnrollment)}</Grid>

      {enrollments.length > 1 && (
        <LoadingButton
          size="small"
          onClick={() => {
            window.open(
              `${window.location.origin}/#${ROUTE_PATHS.ENROLLMENTS.path}?quickFilters=${latestEnrollment.MedicationRequest?.patient?.firstName},${latestEnrollment.MedicationRequest?.patient?.lastName}`,
              "_blank"
            );
          }}
          endIcon={<OpenInNewIcon />}
          sx={{ maxWidth: "fit-content", padding: 0 }}
          data-cy="actionViewEnrollmentsListFromTreatment"
        >
          <u>{`View ${enrollments.length - 1} more enrollments`}</u>
        </LoadingButton>
      )}
    </Stack>
  );
};

export const PatientTreatmentCard: React.FC = () => {
  const { activeRow } = usePatientTreatmentsContext();
  const theme = useTheme();

  const [createBenefitsVerification] =
    useMutation<CreateBenefitsVerificationMutation>(
      CreateBenefitsVerificationMutationGql,
      {
        refetchQueries: [
          ALL_BENEFITS_VERIFICATIONS_QUERY_NAME,
          "getTreatmentsByPatient",
        ],
      }
    );

  const config = useConfig();
  const enhancedMessagingEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableEnhancedServicesMessaging
  );

  if (activeRow?.treatments.length === 0) {
    return (
      <NewPriorAuthButton patientId={activeRow.id}>
        <LoadingButton
          variant="outlined"
          startIcon={<Add />}
          sx={{ whiteSpace: "nowrap" }}
        >
          New Auth
        </LoadingButton>
      </NewPriorAuthButton>
    );
  }
  return (
    <div>
      {activeRow?.treatments.map((service) => {
        return (
          <Paper
            elevation={1}
            sx={{ padding: 0, marginY: 1 }}
            key={service.DrugOptionId}
          >
            <Stack direction="column" padding={4} spacing={2} minWidth={600}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">{service.drugName}</Typography>
                {service.availableEnhancedServices &&
                  service.availableEnhancedServices?.length > 0 &&
                  enhancedMessagingEnabled && (
                    <EnhancedServicesIndicatorFull flexDirection="row-reverse" />
                  )}
              </Stack>
              {service?.supportBvWorkflow && service?.bvs?.length === 0 && (
                <Alert
                  sx={{
                    color: theme.palette.warning.main,
                  }}
                  severity="warning"
                >
                  Start Benefits Verification
                </Alert>
              )}
              {_.some(service.bvs, { isDraft: true }) && (
                <Alert
                  sx={{
                    color: theme.palette.warning.main,
                  }}
                  severity="warning"
                >
                  Complete Benefits Verification
                </Alert>
              )}

              {service.authorizations && service.authorizations.length > 0 && (
                <PatientTreatmentCardAuthDetail
                  auths={service.authorizations}
                  patientId={activeRow.id}
                  expiring={service.expiringAuth ?? false}
                />
              )}

              {service?.supportBvWorkflow && service?.bvs?.length === 0 && (
                <>
                  <Typography variant="h6" color={theme.palette.primary.main}>
                    Benefit Verification
                  </Typography>
                  <LoadingButton
                    variant="contained"
                    onClick={async () => {
                      const { data: createData } =
                        await createBenefitsVerification({
                          variables: {
                            ...(activeRow.id != null && {
                              patientId: parseInt(activeRow.id, 10),
                            }),
                            drugOptionId: service.DrugOptionId,
                          },
                        });
                      const createId =
                        createData?.createBenefitsVerification!.id;
                      window.open(
                        `${window.location.origin}/#${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/edit/${createId}`,
                        "_blank"
                      );
                    }}
                    size="small"
                    sx={{ maxWidth: 250 }}
                    data-cy="actionCreateBenefitsVerificationFromTreatment"
                  >
                    Start Benefit Verification Check
                  </LoadingButton>
                </>
              )}

              {service.bvs && service.bvs.length > 0 && (
                <PatientTreatmentCardBvDeteail
                  bvs={service.bvs}
                  hasAuths={(service.authorizations ?? []).length > 0}
                  patientId={activeRow.id}
                />
              )}

              {service.enrollments && service.enrollments.length > 0 && (
                <PatientTreatmentCardEnrollmentDetail
                  enrollments={service.enrollments}
                />
              )}
            </Stack>
          </Paper>
        );
      })}
    </div>
  );
};
