import { useEffect } from "react";
import { Prescriber } from "@samacare/graphql";
import { useFindPrescriberByNpi } from "@samacare/hooks-data";
import { Box, DialogContent, Stack, Typography } from "@samacare/design";
import {
  DialogFooterWithButtons,
  DialogHeader,
  TopBottomCenterAll,
} from "@samacare/component";
import { FormProvider, TextField, useForm } from "@samacare/form2";
import { CONTENT_HEIGHT } from "./constants";

interface NpiSchema {
  npi: string;
}

interface SearchByNpiPaneProps {
  onFound: (prescriber: Prescriber) => void;
  onError: (error: string, NPI: string) => void;
  onClose: () => void;
}
export const SearchByNpiPane: React.FC<SearchByNpiPaneProps> = (props) => {
  const { onError, onFound, onClose } = props;
  const { findPrescriberByNpi, prescriber, errorMessage } =
    useFindPrescriberByNpi();

  const methods = useForm<NpiSchema>({
    reValidateMode: "onChange",
  });

  const NPI = methods.watch("npi");

  const findPrescriber = ({ npi }: NpiSchema) => {
    findPrescriberByNpi(npi);
  };

  useEffect(() => {
    if (errorMessage) {
      onError(errorMessage, NPI);
    }
  }, [errorMessage, NPI, onError]);

  useEffect(() => {
    if (prescriber) {
      onFound(prescriber);
    }
  }, [prescriber, onFound]);

  return (
    <Stack>
      <DialogHeader title="Add Provider" onClose={onClose} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(findPrescriber)}>
          <DialogContent>
            <TopBottomCenterAll
              sx={{
                gap: 2,
                paddingBottom: 2,
                height: CONTENT_HEIGHT,
                paddingX: 6,
              }}
            >
              <Typography
                variant="body"
                fontWeight="600"
                sx={{ textAlign: "center" }}
              >
                Search for Provider by NPI
              </Typography>
              <Box sx={{ height: "64px", width: "100%" }}>
                <TextField
                  fullWidth
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  label="NPI"
                  name="npi"
                  required
                  rules={{
                    pattern: {
                      value: /^\d{10}$/,
                      message: "Npi must be exactly 10 digits",
                    },
                  }}
                />
              </Box>

              <Typography variant="caption" sx={{ textAlign: "center" }}>
                Key provider information, such as NPI number, is sourced
                directly from National Provider Identifier (NPI) database.
              </Typography>
            </TopBottomCenterAll>
          </DialogContent>
          <DialogFooterWithButtons
            cancelText="Close"
            onCancel={props.onClose}
            confirmText="Search"
            onConfirm={methods.handleSubmit(findPrescriber)}
          />
        </form>
      </FormProvider>
    </Stack>
  );
};
