import _ from "lodash";
import { useState } from "react";
import { compose } from "recompose";

import { withCurrentAccount, withAccounts } from "../../graphql/Account";
import { withLocations } from "../../graphql/Location";
import TabbedHeader from "../../components/MainList/TabbedHeader";
import { SettingsHeader } from "../../components/Settings/SettingsHeader";
import { SettingsMainContainer } from "../../components/Settings/SettingsMainContainer";
import AccountsTable from "./Accounts/AccountsTable";
import LocationsTable from "./Locations/LocationsTable";
import AdminSettings from "./AdminSettings";
import PrescribersTable from "./Prescribers/PrescribersTable";
import PatientsTable from "./Patients/PatientsTable";
import PatientsUpload from "./Upload/PatientsUpload";
import { SectionHeader } from "./SectionHeader";
import { Stack } from "@samacare/design/core";
import SFTPCreate from "./SFTPCreate";
import { useFeatureFlag } from "@@hooks/useFeatureFlag";

const sortNewUser = ({ firstName, lastName }) =>
  firstName === CONFIG.CONSTANTS.DEFAULT_USER.firstName &&
  lastName === CONFIG.CONSTANTS.DEFAULT_USER.lastName
    ? 0
    : 1;

const sortNewLocation = (location) =>
  location.name === CONFIG.CONSTANTS.DEFAULT_LOCATION_NAME ? 0 : 1;

const getAdminTabs = (currentAccount, tabs, conditionalTabs) => {
  const accountIntegrations = _.get(
    currentAccount,
    "institution.integrations",
    []
  );
  const oncoEMRIsEnabled =
    accountIntegrations.filter(
      (installation) =>
        installation.integrationId ===
          CONFIG.CONSTANTS.INTEGRATION_TITLE.oncoEMR && installation.isEnabled
    ).length > 0;

  if (oncoEMRIsEnabled) {
    return tabs.concat(conditionalTabs);
  }
  return tabs;
};

const Admin = ({ account, accounts, locations }) => {
  const isCreateSftpKeysEnabled = useFeatureFlag("create-sftp-keys", false);
  const isShowPatientUploadEnabled = useFeatureFlag(
    "show-patient-upload",
    false
  );

  const tabs = [
    { title: "Users", tabTitle: "Users" },
    { title: "Providers", tabTitle: "Providers" },
    { title: "Locations", tabTitle: "Locations" },
    { title: "Patients", tabTitle: "Patient Admin" },
    isCreateSftpKeysEnabled ? { title: "SFTP", tabTitle: "SFTP" } : null, // Set to null if you want to exclude the tab
    isShowPatientUploadEnabled ? { title: "Upload", tabTitle: "Upload" } : null,
  ].filter(Boolean); // Filter out null values

  const conditionalTabs = [{ title: "EMR Settings", tabTitle: "EMR Settings" }];

  const [view, setView] = useState(tabs[0]);

  if (account && accounts && locations) {
    const updatedUsers = _.reject(accounts, { isSystemUser: true });
    const users = _.sortBy(updatedUsers, [sortNewUser, "isExpired", "id"]);
    const locationList = _.sortBy(locations, [sortNewLocation, "name"]);

    return (
      <SettingsMainContainer>
        <SettingsHeader>
          {`${account.institution.name} Administrative Dashboard`}
        </SettingsHeader>
        <TabbedHeader
          onSelect={(tab) => {
            setView(tab);
          }}
          selected={view}
          tabs={
            account.isAdmin ? getAdminTabs(account, tabs, conditionalTabs) : []
          }
          marginBottom="0px"
        />
        {view.title === tabs[0]?.title && (
          <div>
            <AccountsTable
              accountList={users}
              isSamaUser={account.isSamaUser}
              isAdmin={account.isAdmin}
            />
            {_.isEmpty(users) && (
              <SectionHeader>No users have been added.</SectionHeader>
            )}
          </div>
        )}
        {view.title === tabs[1]?.title && <PrescribersTable />}
        {view.title === tabs[2]?.title && (
          <div>
            <LocationsTable
              locationList={locationList}
              isSamaUser={account.isSamaUser}
            />
            {_.isEmpty(locationList) && (
              <SectionHeader>No locations have been added.</SectionHeader>
            )}
          </div>
        )}
        {view.title === tabs[3]?.title && <PatientsTable />}
        {view.title === tabs[4]?.title && (
          <Stack gap={0.5}>
            <SFTPCreate />
          </Stack>
        )}
        {view.title === tabs[5]?.title && <PatientsUpload />}
        {view.title === conditionalTabs[0]?.title && (
          <AdminSettings account={account} />
        )}
      </SettingsMainContainer>
    );
  }
  return <div />;
};

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withAccounts, withCurrentAccount, withLocations)(Admin);
