import { DialogContent, Stack, Typography } from "@samacare/design";
import {
  DialogFooterWithButtons,
  DialogHeader,
  TopBottomCenterAll,
} from "@samacare/component";
import { CONTENT_HEIGHT } from "./constants";

interface SearchErrorPaneProps {
  errorMessage: string | undefined | null;
  onBack: () => void;
  onClose: () => void;
  onAddManually: () => void;
}
export const SearchErrorPane: React.FC<SearchErrorPaneProps> = (props) => (
  <Stack>
    <DialogHeader title="Add Provider" onClose={props.onClose} />
    <DialogContent>
      <TopBottomCenterAll
        sx={{ gap: 2, paddingBottom: 8, height: CONTENT_HEIGHT, paddingX: 6 }}
      >
        <Typography variant="body" fontWeight="600" textAlign="center">
          {props.errorMessage}
        </Typography>
      </TopBottomCenterAll>
    </DialogContent>
    <DialogFooterWithButtons
      cancelText="Back"
      onCancel={props.onBack}
      confirmText="Add Manually"
      onConfirm={props.onAddManually}
    />
  </Stack>
);
